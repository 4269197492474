<template>
  <div class="service-agreement">
    <h5 class="title">用户协议</h5>
    <div class="privacy-content">
     <p style="text-align: center;"><strong>用户协议</strong></p>
<p>&nbsp;</p>
<ol>
<li>特别提示</li>
</ol>
<p>欢迎您使用MetaIO产品或服务！在使用MetaIO产品及/或服务（以下简称&ldquo;服务&rdquo;或&ldquo;本服务&rdquo;）之前，请您务必认真阅读并充分理解以下内容。</p>
<p>1.1.&nbsp;请您务必认真阅读并严格遵守《用户协议》（以下简称&ldquo;本协议&rdquo;）等相关协议、规则。</p>
<p>1.2.&nbsp;除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用MetaIO服务。您选择以页面确认或其他任何方式明示或者默示表示接受本协议，或以下载、安装、登录等方式使用MetaIO产品及/或服务，均视为您已阅读并同意签署本协议。本协议即在您与MetaIO之间产生法律效力，成为对双方均具有约束力的法律文件。</p>
<p>1.3.&nbsp;如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。</p>
<p>1.4.&nbsp;在您使用本服务的过程中，MetaIO会基于您需求的服务，收集和使用您的个人信息，包括您的身份信息、联系方式等。请您仔细阅读并充分理解《隐私协议》，《隐私协议》将帮助您了解：我们如何收集、使用、共享以及储存您的个人信息。</p>
<p>1.5.&nbsp;您须确保有充分、完整、有效和必要的权利、能力和权限来同意和履行本协议。如果您代表您的雇主或客户同意本协议，您声明您已经取得充分、完整、有效和必要的法律授权使您的雇主或客户受到本协议的约束。</p>
<p>1.6.&nbsp;MetaIO有权随时对本协议进行修订，且无须单独和事先通知您。本协议一旦发生变动，MetaIO将会在本MetaIO官网上公布修订后的本协议并以公示等方式通知用户。如果您不能同意本协议的修订版本，请您立即停止使用本服务。您理解并同意，修订后的本协议条款具有溯及力，如果您在本协议修订版本生效后继续使用本服务的，即视为您同意修订后的本协议。</p>
<p>1.7.&nbsp;请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款等。其中，免除或者限制责任条款等重要内容可能以加粗形式提示您注意，您应重点阅读。您通过网络页面点击确认或以其他方式选择接受本协议或实际已使用本协议服务，同时还表明您已经完全同意MetaIO已经完全履行了相关的提示义务和说明义务。如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并征得法定监护人的同意后使用本服务。。您有违反本协议的任何行为时，MetaIO有权依照违反情况，随时单方限制、中止或终止向您提供服务，并有权追究您的相关责任。</p>
<p>&nbsp;</p>
<ol start="2">
<li>术语含义</li>
</ol>
<p>如无特别说明，下列术语在本协议中的含义为：</p>
<p>2.1.&nbsp;MetaIO：元宇未来科技（北京）有限公司以及相关关联公司，以下或称为&ldquo;我们&rdquo;。</p>
<p>2.2.&nbsp;用户：包括注册用户及浏览用户，以下简称为&ldquo;您&rdquo;。</p>
<p>&nbsp;</p>
<ol start="3">
<li>账号与安全</li>
</ol>
<p>3.1.&nbsp;本服务为保障部分服务及模块的使用，可能要求您创建账号，您完成注册手续后，获得MetaIO账号的使用权。您保证，您注册时所需提供的公司信息/个人信息在各方面均真实、准确、最新、完整，并不断更新注册资料，以符合及时、详尽准确的要求。您不得冒充任何其他人或实体，不得利用他人的名义发布任何信息，不得创建虚假身份误导他人，否则MetaIO有权立即停止提供服务，由此而产生的一切法律责任由您独自承担。您同意：企业账号的操作行为将直接被视为企业的行为，同时，企业应当对其雇员做出的企业账号管理行为负责。</p>
<p>3.2.&nbsp;您使用第三方账号登录MetaIO服务时，可选择授权MetaIO在符合相关法律法规要求的前提下读取并获得您在该第三方平台上登记、公布、记录的公开信息（包括昵称、头像、手机号等，具体以您的授权内容为准）。MetaIO需要您授权从第三方获取上述信息是为了记住您作为用户的登录身份，以向您提供更优质的产品和/或服务。我们仅会在您的授权同意的范围内收集并使用您的个人信息。</p>
<p>3.3.&nbsp;您使用MetaIO服务的行为必须合法并符合本协议，您必须为自己注册账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对其中的内容自行加以判断，并承担因该内容而引起的所有风险，包括因依赖于内容的正确性、完整性或实用性而产生的风险。MetaIO无法且不会对因您的行为而导致的任何损失或损害承担责任。</p>
<p>3.4.&nbsp;您须对自己的登录信息保密以使其不被其他人获取或使用，并且对您在MetaIO账号下的所有行为负责。您必须在第一时间将任何有可能触犯法律的、未获得合法授权或怀疑未获得合法授权的行为，以合理的方式告知MetaIO，MetaIO不对您因未能遵守上述要求而造成的损失承担责任。请勿将您的账号与密码泄露、转让、出售或出借给他人，若您授权他人使用账号，应对被授权人在该账号下发生的所有行为负全部责任。</p>
<p>3.5.&nbsp;MetaIO和您应遵守相关法律法规进行实名认证。就本服务某些模块的开通，MetaIO可能要求您提供进一步的身份资料、经营资质和信息，以完成身份认证或资格验证。仅在完成相应身份认证及资格验证后，您才可以订购及使用相关服务。您理解并同意MetaIO有权自行或委托第三方，审查您在实名认证时提供的信息是否真实、准确及有效。您知晓并同意，如您未依照相关法律法规及MetaIO的要求完成实名认证，您将无法订购本服务的部分内容，也可能将影响您访问或使用本服务。</p>
<p>&nbsp;</p>
<ol start="4">
<li>服务内容</li>
</ol>
<p>4.1.&nbsp;MetaIO产品服务的具体内容由MetaIO根据实际情况提供，MetaIO将会根据实际情况更新MetaIO服务，请以页面中的具体服务内容及相关服务协议为准。</p>
<p>4.2.&nbsp;除双方另有书面约定外，您应（a）确保您的网络和系统符合我们随时提供的相关规范（如有）；（b）全权负责采购和维护从您的系统到我们或任何第三方数据中心的网络连接和电信连接；并且（c）自行承担所有因您的网络连接、电信连接或因互联网引起的或与之相关的所有问题、条件、延迟、交付失败和所有其他损失、损坏、责任、费用或任何性质或种类的费用。</p>
<p>4.3.&nbsp;由于MetaIO无法控制的原因而造成的商业损失、迟延履行或未履行，以及非因MetaIO违反义务而造成的任何损害，MetaIO不承担任何责任。</p>
<p>4.4.&nbsp;在您使用本服务的过程中，MetaIO除会基于您需求的服务，收集和使用您的个人信息外，还会为了改善MetaIO产品的用户体验而启动的通过收集、统计、分析模块使用数据，达到提高产品质量、推出对用户有帮助的创新安全服务的目的，包括产品功能使用量统计数据、产品功能改进统计数据及程序错误日志等。具体可详见《MetaIO关于用户体验改进计划的声明》。</p>
<p>&nbsp;</p>
<ol start="5">
<li>第三方产品和服务</li>
</ol>
<p>5.1.&nbsp;MetaIO服务可能包含第三方提供的产品或服务。当用户使用第三方提供的产品或服务时，可能会另有相关的协议或隐私政策，您同样应当认真阅读并遵守。</p>
<p>5.2.&nbsp;您须理解并同意：为了向您提供更好的用户体验，当您在使用第三方服务时，第三方可能会接触、使用到您的昵称、头像以及在第三方服务中的相关操作信息等信息。</p>
<p>5.3.&nbsp;MetaIO服务或第三方可能会提供互联网上其他网站或资源的链接。鉴于这些网站与资源不在MetaIO的控制之下，您须理解并同意：MetaIO不对这些网站或资源的有效性负责，亦不会对源于这些网站或资源的任何内容，包括但不限于广告、产品或其他任何材料负责或做出任何保证。MetaIO将不承担因使用或依赖于从这些网站或资源中获取的或发布于这些网站或资源中的任何内容、广告、产品或服务而造成的任何损失或损害。</p>
<p>5.4.&nbsp;第三方内容基于&ldquo;现状&rdquo;和&ldquo;当前可用&rdquo;而提供。如您在使用第三方产品或服务时发生任何纠纷，请直接与第三方联系，我们不对任何第三方内容作出任何形式的陈述或保证，也不对任何第三方内容造成的任何性质或种类的损失、损害、费用或成本承担任何责任，但会根据需要依法提供必要的协助。</p>
<p>&nbsp;</p>
<ol start="6">
<li>权利限制</li>
</ol>
<p>6.1.&nbsp;您在使用MetaIO服务时，必须遵守中华人民共和国相关法律法规的规定，您应同意将不会利用MetaIO服务进行任何违法或不正当的活动，包括但不限于下列行为∶</p>
<p>6.1.1.&nbsp;危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
<p>6.1.2.&nbsp;损害国家荣誉和利益的；</p>
<p>6.1.3.&nbsp;煽动民族仇恨、民族歧视、破坏民族团结的；</p>
<p>6.1.4.&nbsp;破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
<p>6.1.5.&nbsp;散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
<p>6.1.6.&nbsp;散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
<p>6.1.7.&nbsp;侮辱或者诽谤他人，侵害他人合法权利的；</p>
<p>6.1.8.&nbsp;煽动非法集会、结社、游行、示威、聚众扰乱社会秩序、以非法民间组织名义活动的；</p>
<p>6.1.9.&nbsp;含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它有悖于道德的内容；</p>
<p>6.1.10.&nbsp;含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</p>
<p>6.2.&nbsp;您应同意不利用MetaIO服务从事下列行为：</p>
<p>6.2.1.&nbsp;上传或发布电脑病毒、蠕虫、恶意代码、故意破坏或改变计算机系统或数据的软件；</p>
<p>6.2.2.&nbsp;未获授权的情况下，收集其他用户的信息或数据，例如电子邮箱地址等；</p>
<p>6.2.3.&nbsp;禁用本网站的网络连接，给本网站造成过度的负担或以其他方式干扰或损害网站服务器和网络链接；</p>
<p>6.2.4.&nbsp;恶意注册MetaIO账号或干扰、破坏其他用户正常使用MetaIO服务。</p>
<p>6.3.&nbsp;您违反本协议或相关的服务条款的规定，或因此导致任何第三方主张的任何索赔、要求或损失（包括合理的律师费），您同意赔偿MetaIO及其合作公司，并使之免受损害。就此，MetaIO有权视您的行为性质，采取包括但不限于删除用户发布的信息内容、暂停使用许可、终止服务、限制使用、收回MetaIO账号、追究法律责任等措施。同时，MetaIO将根据司法部门的要求，协助调查。</p>
<p>6.4.&nbsp;您须对自己在使用MetaIO服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在MetaIO先行承担了因您的行为而导致的行政处罚或侵权损害赔偿责任后，您应给予MetaIO等额的赔偿。</p>
<p>6.5.&nbsp;MetaIO或MetaIO委托的第三方有权对您使用MetaIO服务的行为及信息进行审查、监督及处理，包括但不限于用户个人信息（账号信息等）、 发布内容（如位置、文字、图片、音频、视频、商标、专利、出版物等）、用户行为（如构建关系、信息、评论、私信、参与话题、参与活动、营销信息发布、举报投诉等）等范畴。</p>
<p>&nbsp;</p>
<ol start="7">
<li>用户数据的保存、披露与删除</li>
</ol>
<p>7.1.&nbsp;您的用户数据将在下述情况下部分或全部被披露：</p>
<p>7.1.1.&nbsp;经您同意，向第三方披露；</p>
<p>7.1.2.&nbsp;根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
<p>7.1.3.&nbsp;如果您出现违反中国有关法律法规的情况，需要向第三方披露；</p>
<p>7.1.4.&nbsp;为提供您所要求的软件或服务，而必须和第三方分享您的数据。</p>
<p>&nbsp;</p>
<ol start="8">
<li>免责声明与责任限制</li>
</ol>
<p>基于互联网的开放性和特殊性，MetaIO特别提醒您谨慎注意以下风险：</p>
<p>8.1.&nbsp;MetaIO服务包括但不限于文本、图片、数据、观点、建议、网页与链接。MetaIO将尽可能为您提供准确的信息，但在免费使用模式下，MetaIO提供或开发的数据将严格仅为交流目的向您授权使用，使用本服务，即视为您已经知悉并认可按现状使用，MetaIO不对有关服务、数据的权利归属、准确性、完整性、遗失、错误或瑕疵做任何形式（明示或默示）或源于交易习惯、使用方法或其他的声明或保证。</p>
<p>8.2.&nbsp;MetaIO的服务是按照现有技术和条件所能达到的现状提供的。在法律允许的最大范围内，除明确列于本协议之内容外，我们不提供任何其他的条件、声明或做任何保证。MetaIO会尽最大努力向您提供服务，确保服务的连贯性和安全性；但MetaIO不能保证其所提供的服务毫无瑕疵，也无法随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。所以您也同意：即使MetaIO提供的服务存在瑕疵，但上述瑕疵是当时行业技术水平所无法避免的，其将不被视为MetaIO违约，同时，由此给您造成的数据或信息丢失等损失的，您同意放弃追究MetaIO的责任。如因系统维护或升级而需暂停服务时，MetaIO将事先公告。若因线路及非MetaIO控制范围内的硬件故障或其它不可抗力而导致暂停服务的，暂停服务期间造成的一切不便与损失，MetaIO不负任何责任。</p>
<p>8.3.&nbsp;在您当地法律允许的范围内，我们排除有关适销性、质量满意度、特定用途的适用性、无工艺问题和不侵权的任何默示保证。</p>
<p>8.4.&nbsp;MetaIO部分服务（包括但不限于问答、博客等）由用户创建，相关内容仅代表作者观点，与MetaIO无关，MetaIO不对用户发布内容进行任何编辑或修改。对于用户言论的真实性引发的全部责任，由用户自行承担，MetaIO不承担任何责任。</p>
<p>8.5.&nbsp;MetaIO不能控制用户发布内容的合法性以及其来源、归属，不承担任何预先审核义务，也不提供任何种类的明示或暗示担保或条件。MetaIO提醒您应当通过自己的谨慎判断以确定发布内容是否合法有效，同时对其他用户使用MetaIO服务所产生的信息进行独立判断并承担因依赖该内容而引起的所有风险（包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险），以及因用户个人信息被其他用户获知而带来的风险。您了解并确认，MetaIO不对MetaIO服务用户之间的纠纷承担任何责任。</p>
<p>8.6.&nbsp;MetaIO不能控制或保证用户完全遵照本协议发布或使用发布内容，MetaIO提醒用户可能存在用户超出本协议范围进行发布或使用的侵权行为，用户应自行承担因此而产生的一切损失。MetaIO仅在必要时提供协助义务。</p>
<p>8.7.&nbsp;用户之间因使用本服务而产生或可能产生的任何纠纷和/或损失，由用户自行解决并承担相应的责任，与MetaIO无关。MetaIO不涉及任何基于发布内容而产生的包括权属确认、使用授权等在内的一切法律关系及法律纠纷。</p>
<p>8.8.&nbsp;请您切勿透露任何涉及财产、账号及密码等的重要信息，否则，您应自行承担由此带来的损失。</p>
<p>8.9.&nbsp;如果您或其他用户违反本协议而导致被MetaIO依约处理的，您在MetaIO服务中的内容、信息等数据将可能会受到影响。</p>
<p>8.10.&nbsp;除非本协议另有规定或经双方书面同意，如果我们违反本协议，您同意MetaIO对您的唯一的补救措施为针对您的直接且已证明的损害向您退款，且退款金额的上限为发生损害或违约的当年度您向MetaIO支付的该项服务的年度授权服务费（或，当您以免费的方式获得MetaIO服务时此赔偿将不高于壹元人民币）。您不能要求其他任何损害或损失赔偿，包括但不限于直接损害、后果性损害、特别的损害、利润损失、附带的或惩罚性损害赔偿。在法律允许的最大范围内，这些限制和排除条款适用于与本协议相关的任何事项或任何索赔，即使这一补救措施并未完全补偿您的所有损失，也未能达到其根本目的，或者我们知道或应当知道可能会发生损失。</p>
<p>8.11.&nbsp;对于超出MetaIO合理控制范围的情况（例如，因恶意破坏、意外事故、操作环境不符或其他设备故障）造成MetaIO无法履行或延迟履行服务义务的，MetaIO对此不承担任何责任。MetaIO将尽最大努力降低这些事件的影响，并履行未受影响的义务。</p>
<p>8.12.&nbsp;您理解并同意，任何经MetaIO上传、发布的图片、模型及其他资料均为内容提供者上传，MetaIO无法控制传输的内容，不保证内容的合法性、准确性和完整性，不对因内容问题造成的损失承担责任。但MetaIO有权对相关内容及用户账号采取处理措施，包括但不限于通知、冻结、屏蔽、断开链接、删除等。</p>
<p>8.13.&nbsp;在相关数据的收集、存储、处理、使用、共享、传输和交易等过程中，用户因存储服务提供商的原因造成的任何损失，责任由存储服务提供商承担，MetaIO不承担任何责任，亦不进行任何补偿。</p>
<p>&nbsp;</p>
<ol start="9">
<li>用户授权</li>
</ol>
<p>9.1.&nbsp;为持续改善MetaIO为用户提供的各项服务，用户理解并且同意，用户在本服务相关平台所上传、发布或传输内容，MetaIO可以进行合理使用，并可就相关侵权行为采取必要的维权措施，以维护用户及MetaIO的合法权益。但本授权不适用于用户明确表示不可摘录、转载和引用，或通过其他方式明确告知MetaIO不享有上述授权的情形。</p>
<p>9.1.1.&nbsp;用户授权MetaIO可在法律允许的范围内就发布内容加以使用；</p>
<p>9.1.2.&nbsp;上述授权为普通全球授权，并可分授权或转授权；</p>
<p>9.2.&nbsp;其他任何第三方如需使用用户在本服务相关平台所上传、发布或传输内容的应自行与发布用户联系，已确保获得合法授权。</p>
<p>9.3.&nbsp;授权终止</p>
<p>9.4.&nbsp;如若用户撤销发布内容，则该授权自撤销行为完成时停止，该撤销行为对已发生的授权使用不具备溯及力，已授权行为可且仅可在原有范围内继续使用；除上述情况外，该授权持续生效。</p>
<p>9.5.&nbsp;MetaIO在此声明上述授权协议并不代表MetaIO已就用户在本服务相关平台所上传、发布或传输内容予以使用，不构成任何证明MetaIO使用相关内容的依据。</p>
<p>9.6.&nbsp;您同意MetaIO根据需要将您作为典型客户案例进行推广宣传并使用您的字号、标识和商标以及本服务部分展示素材。MetaIO保证在前述推广宣传中不发表任何对您或您的关联公司不利的或容易引起误导的言论。在符合本协议要求的情况下，您可在使用本服务的过程中使用MetaIO标志用以宣传或说明在您的产品或服务中使用了MetaIO服务。除此之外，您不得以任何方式使用MetaIO的商业名称、商标、服务标记、徽标、域名，以及其他明显的标志。</p>
<p>&nbsp;</p>
<ol start="10">
<li>服务的变更、中断、终止</li>
</ol>
<p>10.1.&nbsp;经事先告知，MetaIO可以对服务内容进行变更、中断、中止或终止。</p>
<p>10.2.&nbsp;如发生下列任何一种情形，MetaIO有权视您的行为性质，不经通知而采取包括但不限于删除您发布内容、暂停使用许可、限制使用、终止服务、回收账号、追究法律责任等措施。同时，MetaIO会视相关部门的要求，协助调查：</p>
<p>10.2.1.&nbsp;根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册信息不一致又未能提供合理证明；</p>
<p>10.2.2.&nbsp;您模仿MetaIO管理人员，用以假冒MetaIO管理人员或破坏管理人员形象；</p>
<p>10.2.3.&nbsp;您违反相关法律法规或本协议的约定；</p>
<p>10.2.4.&nbsp;按照法律规定或主管部门的要求；</p>
<p>10.2.5.&nbsp;出于安全原因或其他必要的情形；</p>
<p>10.2.6.&nbsp;您的基本条件发生变化、诚信不佳或出现其他原因导致MetaIO认为您不符合用户资格时；</p>
<p>10.2.7.&nbsp;您出现任何其他损害MetaIO利益的行为时；</p>
<p>10.2.8.&nbsp;若您使用收费项目，而未按时足额付费；</p>
<p>10.2.9.&nbsp;出现本协议第6条所列情形或其他MetaIO认为不恰当的情况。</p>
<p>10.3.&nbsp;若用户在本服务相关平台发布内容出现以下情况之一，MetaIO管理人员有权不提前通知用户直接删除，并依照有关规定作相应处罚。情节严重者，MetaIO管理人员有权关闭部分用户权限、暂停直至删除用户账号，情节严重的，用户还将承担相应的法律责任。</p>
<p>10.3.1.&nbsp;发表含有本协议禁止发布、传播内容的内容；</p>
<p>10.3.2.&nbsp;发表不符合版面主题，或者无内容的内容；</p>
<p>10.3.3.&nbsp;同一内容多次出现的；</p>
<p>10.3.4.&nbsp;违反本协议规定发布广告的；</p>
<p>10.3.5.&nbsp;内容或个人签名会包含有严重影响用户浏览的内容或格式的；</p>
<p>10.3.6.&nbsp;其他MetaIO认为不恰当的情况。</p>
<p>10.4.&nbsp;MetaIO依据本协议约定获得处理违法违规内容的权利，该权利不构成MetaIO的义务或承诺，MetaIO不能保证及时发现违法行为或进行相应处理。</p>
<p>10.5.&nbsp;用户理解并同意，本协议旨在保障遵守国家法律法规、维护公序良俗，保护用户和他人合法权益，MetaIO在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证MetaIO判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。</p>
<p>10.6.&nbsp;如本协议或本服务因为任何原因终止的，对于您的账号中的全部数据或您因使用本服务而产生的数据等任何信息，MetaIO可根据情况自主选择将该等信息保留或删除，包括服务终止前您尚未完成的任何数据。</p>
<p>10.7.&nbsp;如本协议或本服务因为任何原因终止的，您应自行处理好关于数据等信息的备份以及与您的用户之间的相关事项的处理等，由此造成MetaIO损失的，您应负责赔偿。</p>
<p>&nbsp;</p>
<ol start="11">
<li>知识产权</li>
</ol>
<p>11.1.&nbsp;除另有特别声明外，MetaIO在服务中提供的内容（包括但不限于网页、文本、图片、音频、视频、图表、软件等）的知识产权归MetaIO所有，您在使用MetaIO服务过程中所产生的内容的知识产权权利归属以页面中的具体服务内容及相关服务协议为准。未经MetaIO或相应的权利人事先书面同意，您不得实施、利用、转让或许可任何第三方实施、利用或转让上述知识产权；不得修改、出租、出借、出售、发行或创建相关衍生作品，或以其他方式使用上述全部或任一部分知识产权；不得规避或禁用任何MetaIO服务所采用的任何内容保护系统或数字版权管理技术；不得反向编译、逆向工程、反向汇编或以其他方式将任何MetaIO服务还原为其他形式；不得移除或隐藏标识、版权或其他所有权标识；不得以建立具有竞争力的产品或服务的目的使用本服务；不得以违反法规，或未获合法授权，或假借、冒充、搭便车等的方式经营、开展、使用与我们相同或近似的产品、服务、品牌，引人误解或混淆该等产品、服务、品牌等与我们存在特定之联系。</p>
<p>11.2.&nbsp;用户在使用本服务相关平台时所上传、发布或传输的文字、图片、视频、音频等用户原创的内容或已获合法授权内容的知识产权归属用户、原始著作权人或其他相关权利人所有。</p>
<p>11.3.&nbsp;MetaIO在本服务中所使用的任何商业标志、标识、商号、企业名称、域名、刊号及其他含有如&ldquo;MetaIO&rdquo;等的特有标识，其专有权归MetaIO所有。用户在任何情况下都不得私自使用MetaIO的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等，也不得擅自进行修改、遮挡或删除。</p>
<p>&nbsp;</p>
<ol start="12">
<li>侵权投诉通知</li>
</ol>
<p>12.1.&nbsp;MetaIO尊重并保护用户及他人的知识产权、名誉权、姓名权、隐私权等合法权益。用户保证，在使用本协议服务时上传的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，MetaIO有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，用户应自行处理并承担可能因此产生的全部法律责任；如因用户的侵权行为导致MetaIO及其控制公司、继承公司遭受损失的（包括但不限于经济、商誉等损失），用户还应足额赔偿MetaIO遭受的全部损失。如果您认为MetaIO的发布作品侵犯您所享有的著作权，请以书面通知的形式向MetaIO提供以下信息以便MetaIO进行移除，通知书应包括以下内容：</p>
<p>12.1.1.&nbsp;涉嫌侵权内容所侵犯的著作权权属证明，如登记证书、创作手稿、作品发表时间证明、认证机构出具的权属证明等；</p>
<p>12.1.2.&nbsp;权利人的姓名（名称）、联系方式、联系地址、身份证明（自然人需提供身份证复印件或原件扫描件，公司、企业或社会团体需提供营业执照、事业单位法人证书、社会团体法人登记证书等证件的复印件或原件扫描件，境外公司主体需提供境外企业续存证明（经使馆认证）及中文译本；</p>
<p>12.1.3.&nbsp;委托证明文件（如有）；</p>
<p>12.1.4.&nbsp;要求移除的发布内容的准确名称、具体位置和网络地址；</p>
<p>12.1.5.&nbsp;侵犯著作权的相关证据；</p>
<p>12.1.6.&nbsp;通知书内容的真实性声明。</p>
<p>12.2.&nbsp;通知书需著作权人亲笔签名，若为公司、企业或社会团体则需加盖公章。</p>
<p>12.3.&nbsp;MetaIO的联系邮箱为gwyzm@metaio.cc；联系电话为：13311205837。</p>
<p>12.4.&nbsp;MetaIO将根据您发出的通知自主决定采取冻结、屏蔽、删除等合理处置措施，并保留该通知6个月。但若您发出的通知书不符合上述约定，则视为未发出，MetaIO将不会采取任何措施。</p>
<p>12.5.&nbsp;MetaIO在审查过程中有权视具体情况要求投诉人提供或补充相应材料，投诉人应在MetaIO设定的合理期限内予以答复或补充，期满未作答复或补充的，视为投诉人撤回投诉。</p>
<p>12.6.&nbsp;投诉人应注意，MetaIO在采取冻结、屏蔽、删除等合理处置措施后，会将该投诉及相关处置措施告知被投诉人，如被投诉人认为其行为并未侵犯他人的合法权益请在告知设定的合理期限内提供未侵权证明材料，MetaIO在接到材料后悔及时审核并将该材料转送至投诉人，若审核后认为被投诉人证据齐全理由充分，将视情况决定是否解除签署合理处置措施，并告知投诉人向有关部门投诉或向人民法院起诉。</p>
<p>12.7.&nbsp;在处理侵权投诉的过程中，因合理的处置行为对双方造成的损失，MetaIO不承担任何责任。</p>
<p>&nbsp;</p>
<ol start="13">
<li>不可抗力</li>
</ol>
<p>13.1.&nbsp;您理解并同意，在使用MetaIO服务的过程中，可能会遇到不可抗力等风险因素，使MetaIO服务发生中断。不可抗力是指不能预见、不能避免并不能克服的且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等，以及社会事件如战争、动乱、政府行为等。出现上述情况时，MetaIO将在第一时间与相关单位配合，及时进行修复，但是在法律允许的范围内MetaIO免于承担由此给您造成的损失。</p>
<p>13.2.&nbsp;在法律允许的范围内，因以下情形导致的服务中断或受阻，MetaIO将不承担责任：</p>
<p>13.2.1.&nbsp;非因MetaIO违反作为网络运营商的义务而遭受的计算机病毒、木马病毒或其他恶意程序、黑客攻击的破坏；</p>
<p>13.2.2.&nbsp;您或MetaIO的电脑软件、系统、硬件和通信线路出现故障；</p>
<p>13.2.3.&nbsp;您错误或不当操作；</p>
<p>13.2.4.&nbsp;您通过非MetaIO授权的方式使用本服务；</p>
<p>13.2.5.&nbsp;其他MetaIO无法控制或合理预见的情形。</p>
<p>&nbsp;</p>
<ol start="14">
<li>关于通知</li>
</ol>
<p>14.1.&nbsp;MetaIO可能会以网页公告、网页提示、电子邮箱、手机短信、QQ、常规的信件传送、您注册的本服务账号的管理系统内发送站内信等方式中的一种或多种，向您送达关于本服务的各种规则、通知、提示等信息，该等信息一经MetaIO采取前述任何一种方式公布或发送，即视为送达，对您产生约束力。若您不接受的，请您书面通知MetaIO，否则视为您已经接受、同意。</p>
<p>14.2.&nbsp;若由于您提供的电子邮箱、手机号码、通讯地址等信息错误，导致您未收到相关规则、通知、提示等信息的，一切后果及责任由您自行承担。</p>
<p>14.3.&nbsp;您也同意MetaIO或合作伙伴可以向您的电子邮件、手机号码等发送可能与本服务不相关的其他各类信息包括但不限于商业广告等。</p>
<p>14.4.&nbsp;若您有事项需要通知MetaIO的，应当按照本服务对外正式公布的联系方式书面通知MetaIO。</p>
<p>&nbsp;</p>
<ol start="15">
<li>法律适用与管辖</li>
</ol>
<p>15.1.&nbsp;本协议签订地为中华人民共和国北京市东城区。</p>
<p>15.2.&nbsp;本协议的订立、生效、履行、解释与争议解决，均适用中华人民共和国大陆地区法律。</p>
<p>15.3.&nbsp;若您和MetaIO之间发生任何纠纷或争议，双方首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</p>
<p>&nbsp;</p>
<ol start="16">
<li>其他</li>
</ol>
<p>16.1.&nbsp;本协议条款无论因何种原因部分无效或不可执行，其余条款仍然有效，并对双方具有约束力。</p>
<p>16.2.&nbsp;本协议条款终止后，该条款中依其性质应继续有效的规定，将继续有效。</p>
<p>16.3.&nbsp;本协议内容同时包括《MetaIO隐私政策》及其他附属协议，请务必仔细阅读《MetaIO隐私政策》等附属协议。您对本协议的接受，即视为您对《MetaIO隐私政策》及其附属协议的接受。某些MetaIO服务可能附有补充约定，例如就特定的平台、事项、软件、应用程序或其他服务的规约（以下称为&ldquo;单独协议&rdquo;）。任何单独协议均为本协议的补充，如有冲突，以单独协议约定为准。《MetaIO隐私政策》等附属协议以及可能涉及的单独协议一经正式发布，均为本协议不可分割的一部分，与本协议具有同等法律效力，您同样应当遵守。您对前述单独协议的接受，即视为您对本协议、《MetaIO隐私政策》及其附属协议的接受。</p>
<p>16.4.&nbsp;MetaIO对本协议条款中任何规定的放弃不应被视为对该规定或任何其他规定的进一步或持续的放弃。MetaIO未依本协议条款主张权利，不应构成对该权利或该条款的放弃。</p>
<p>16.5.&nbsp;本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
<p>&nbsp;</p>
<p>元宇未来科技（北京）有限公司</p>
<p>2022年7月1日</p>
<p>&nbsp;</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "serviceAgreement",
  methods: {},
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
  ::v-deep #app{
    overflow-y: scroll;
  }
.service-agreement {
  color: #000;
  width: calc(100% - 400px);
  margin: 179px auto 151px;
  padding-bottom: 100px;
  overflow-y: scroll;
  .title {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 59px;
    text-align: center;
  }
  .privacy-content {
    font-size: 34px;
    h5 {
      color: #000;
      font-weight: 700;
      margin: 20px 0;
      &.big {
        font-size: 48px;
      }
    }
    p {
      color: #606060;
      &.right {
        text-align: right;
        margin-top: 30px;
      }
      &:last-child {
        padding-bottom: 100px;
      }
    }
  }
}
</style>
